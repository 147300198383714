import React, { useMemo, useState } from "react"
import moment from "moment"
import { Paper, Box, Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans, Plural, t } from "@lingui/macro"
import {
  ModuleHeading,
  Shine,
  RowBox,
  Icon,
  CompletedByAvatar,
  InlineEditTextField,
  InfoSection,
  AuthorTools,
  FormatDateTimeCompact,
} from ".."
import TimeAgo from "../TimeAgo"
import { CopyLinkButton, DownloadExportJobButton } from "../Buttons"
import { getResponseStepsCount, toId, useJobUtils, useFormUtils, useDateUtils } from "../../utils"
import { useAuth } from "../../services"
import { JobScheduledMessage } from "./JobScheduledMessage"
import { JobMoreInformation } from "./JobMoreInformation"
import { useMutationJobUpdateTitle } from "../../data/jobs/useMutationJob"
import { PROCESS_TYPE } from "../../data"
import { AuditStatusChip } from "../Chips/AuditStatusChip"

const useStyles = makeStyles((theme) => ({
  paper: {},
  hasActionItems: {
    color: theme.palette.raised.main,
  },
  hasNoteItems: {
    color: theme.palette.raised.secondary,
  },
  detail: {
    borderRadius: 8,
    padding: "1px",
  },
  detailOverdue: {
    border: `solid 2px ${theme.palette.error.main}`,
    padding: 0,
  },
  detailCompleted: {
    backgroundColor: theme.palette.success.background,
    padding: 0,
    border: `solid 2px ${theme.palette.success.main}`,
  },
  detailInner: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.text.primary,
  },
  detailName: {
    fontSize: 26,
    lineHeight: "36px",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(0),
    },
  },
  detailJobOptions: {
    gap: theme.spacing(1),
  },
  detailAuditCompletion: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: theme.spacing(0),
    },
  },
  detailAuditTemplate: {
    color: theme.palette.text.secondary,
    fontSize: 18,
    minHeight: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  detailAuditEdit: {
    height: theme.spacing(4),
  },
  detailAuditDate: {
    flexShrink: 0,
  },
  detailStats: {
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      gap: theme.spacing(1),
    },
  },
  detailStat: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  detailFigure: {
    fontSize: 16,
    fontWeight: 600,
  },
  detailCaption: {
    fontSize: 14,
    marginBottom: theme.spacing(0),
  },
}))

const ProcessHeader = ({ job, isInline, isParentSubmitted, onDelete, deleteLoading }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { isJobAfterToday, isJobOverdue } = useJobUtils()
  const [updateJobTitle] = useMutationJobUpdateTitle(job)
  const { isValid } = useFormUtils()
  const {
    title,
    status: { completed, completedAt, steps: allSteps, submitted, submittedAt, submittedBy },
    availableFrom,
    location: { timeZone },
  } = job
  const scheduled = isJobAfterToday(availableFrom)
  const overdue = isJobOverdue(job)
  const classes = useStyles({ overdue })
  const nested = !!job.parent?.job

  const [currentTitle, setCurrentTitle] = useState(title || "")
  const { formatShortDate } = useDateUtils()
  const { hasPermission } = useAuth()

  const expansionClasses = {
    root: classes.expansionRoot,
    icon: classes.expansionIcon,
    summary: classes.expansionSummary,
    detail: classes.expansionDetail,
  }

  const [completedCount, lastStepCompletedAt] = useMemo(() => {
    let at
    const count = allSteps.filter((step) => {
      if (step.completedAt && (!at || moment(step.completedAt).isAfter(at))) at = step.completedAt
      return step.completedAt
    }).length

    return [count, at]
  }, [allSteps])

  const handleUpdateTitle = async (newTitle) => {
    if (currentTitle === newTitle) {
      return
    }

    let titleToSubmit = newTitle

    if (!isValid(newTitle)) {
      titleToSubmit = ""
      setCurrentTitle("")
    } else {
      titleToSubmit = newTitle
      setCurrentTitle(newTitle)
    }

    try {
      const result = await updateJobTitle({ variables: { job: toId(job), title: titleToSubmit } })
      if (result.errors) {
        setCurrentTitle(currentTitle)
      }
    } catch (error) {
      setCurrentTitle(currentTitle)
    }
  }

  const handleDelete = () => {
    onDelete && onDelete()
  }

  const isSubmitted = submitted || isParentSubmitted

  const responseStepCount = getResponseStepsCount(allSteps)

  const unresolvedActionNestedCount = job.status.steps?.reduce(
    (acc, step) => acc + (step?.job?.unresolvedActions || 0),
    0,
  )

  const unresolvedActionCount =
    isSubmitted &&
    job.status.steps.reduce((acc, step) => acc + step.unresolvedActions.length, 0) + unresolvedActionNestedCount

  const noteNestedCount = isSubmitted && job.status.steps.reduce((acc, step) => acc + (step?.job?.notes || 0), 0)
  const noteCount = isSubmitted && job.status.steps.reduce((acc, step) => acc + step.notes.length, 0) + noteNestedCount

  const showSubmittedOutputBlock =
    submitted && ((job.hasScoring && job.status.possibleScore > 0) || unresolvedActionCount > 0 || noteCount > 0)

  const canUpdate = hasPermission("job_update_all")

  const audit = job.type === PROCESS_TYPE.AUDIT
  const subject = audit ? t`audit` : t`job`

  const isCompleteOrSubmitted =
    (job.type === PROCESS_TYPE.AUDIT && submitted) || (job.type === PROCESS_TYPE.PROCESS && completed)

  return (
    <>
      <Paper
        elevation={0}
        className={`${classes.paper} ${classes.detail} ${isCompleteOrSubmitted ? classes.detailCompleted : ""} ${
          overdue ? classes.detailOverdue : ""
        }`}
      >
        <Box>
          <Shine go={isCompleteOrSubmitted}>
            <Box className={classes.detailInner}>
              <RowBox className={classes.detailName}>
                <RowBox>
                  <ModuleHeading id="process-title" value={job.process.name} noMargin />
                </RowBox>

                {/* Both processes and audits to use combined progress chip */}
                {!nested && !xs && (
                  <RowBox ml="auto" className={classes.detailAuditCompletion} alignSelf="flex-start">
                    <Box>
                      <AuditStatusChip job={job} overdue={overdue} />
                    </Box>
                  </RowBox>
                )}
              </RowBox>

              {/* Editable titles only on adhoc processes */}
              <RowBox
                className={`${classes.detailAuditTemplate} ${job.adhoc ? classes.detailAuditEdit : ""}`}
                ml="auto"
              >
                <Box className={job.adhoc ? classes.detailAuditDate : ""}>
                  {formatShortDate(job.createdAt, timeZone)}
                  {!job.adhoc && job.process.name !== job.scheduleName && `: ${job.scheduleName}`}
                </Box>
                {job.adhoc && (
                  <>
                    :{" "}
                    <InlineEditTextField
                      value={currentTitle}
                      placeholder={<Trans>Add optional title...</Trans>}
                      editable={!scheduled && !nested && "jobprocess_update_status"}
                      onEnd={handleUpdateTitle}
                      flexGrow={1}
                    />
                  </>
                )}
              </RowBox>

              {/* Display if the job is set to run in the future */}
              {scheduled && (
                <RowBox mt={1}>
                  <JobScheduledMessage type="Process" availableFrom={availableFrom} />
                </RowBox>
              )}

              {/* Show chip below titles on mobile */}
              {!nested && xs && (
                <RowBox mt={1}>
                  <AuditStatusChip job={job} overdue={overdue} />
                </RowBox>
              )}
            </Box>

            {/* Show the copy link, PDF and options at top level */}
            {!nested && (
              <>
                <InfoSection
                  title={<Trans>Job options</Trans>}
                  rightChildren={
                    <Box
                      ml="auto"
                      display="flex"
                      alignItems="center"
                      className={classes.detailJobOptions}
                      flexDirection="row"
                    >
                      <DownloadExportJobButton variant="icon" iconSize="small" job={job} disabled={scheduled} />
                      <CopyLinkButton iconSize="small" />
                      {canUpdate && (
                        <AuthorTools
                          item={job}
                          author={job.author.id}
                          admins={{ delete: "job_update_all" }}
                          subject={`${subject} ${t`(this instance only)`}`}
                          onDelete={handleDelete}
                          disabled={scheduled}
                          loading={deleteLoading}
                          p={0.5}
                        />
                        // O-i18n TODO this is not working correctly
                      )}
                    </Box>
                  }
                />
              </>
            )}

            {/* Display avatar once job is submitted */}
            {submitted && (
              <InfoSection
                title={<Trans>Submitted</Trans>}
                value={
                  <Trans context="Records time submitted by a person">
                    <FormatDateTimeCompact value={submittedAt} timeZone={timeZone} />, by {submittedBy?.fullName}
                  </Trans>
                }
                compact
                rightChildren={
                  <>
                    <CompletedByAvatar text={false} time={false} at={submittedAt} by={submittedBy} />
                  </>
                }
              />
            )}

            {/* Display completion date once process is done */}
            {completed && !audit && (
              <InfoSection
                title={<Trans>Completed</Trans>}
                value={
                  <>
                    <FormatDateTimeCompact value={completedAt} timeZone={timeZone} />
                  </>
                }
                compact
              />
            )}

            {/* Step completion for processes */}
            {!scheduled && job.type === PROCESS_TYPE.PROCESS && (
              <InfoSection
                title={<Trans>Steps completed</Trans>}
                value={
                  <Box>
                    {completedCount}/{responseStepCount}
                  </Box>
                }
                compact
              />
            )}

            {/* Step completion for audits */}
            {!scheduled && job.type === PROCESS_TYPE.AUDIT && (
              <InfoSection
                title={<Trans>Steps completed</Trans>}
                value={
                  <Box>
                    {completedCount}/{responseStepCount}
                  </Box>
                }
                compact
              />
            )}

            {/* Location where this job is being run */}
            <InfoSection title={<Trans>Location</Trans>} value={job.location.name} compact />

            {/* Most recent completion */}
            {!!lastStepCompletedAt && (
              <InfoSection
                title={<Trans>Most recent completion</Trans>}
                value={<TimeAgo date={lastStepCompletedAt} />}
                compact
              />
            )}
          </Shine>
          {!nested && (
            <JobMoreInformation
              job={job}
              isInline={isInline}
              classes={classes}
              expansionClasses={expansionClasses}
              onDelete={handleDelete}
              deleteLoading={deleteLoading}
              nested={nested}
            />
          )}

          {/* Score, action and note summary once completed */}
          {showSubmittedOutputBlock && (
            <>
              <Box>
                <Grid container direction="row" className={classes.detailStats}>
                  {job.hasScoring && job.status.possibleScore > 0 && (
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.detailStat}>
                        <Icon name="score" />
                        <Typography className={classes.detailFigure}>
                          {Math.round((job.status.score / job.status.possibleScore) * 100)}%
                        </Typography>
                        <Typography className={classes.detailCaption}>
                          <Trans>Audit score</Trans>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {unresolvedActionCount > 0 && (
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.detailStat}>
                        <Icon name="action-raised" className={classes.hasActionItems} />
                        <Typography className={classes.detailFigure}>{unresolvedActionCount}</Typography>
                        <Typography className={classes.detailCaption}>
                          <Plural value={unresolvedActionCount} one="Unresolved action" other="Unresolved actions" />
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {noteCount > 0 && (
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.detailStat}>
                        <Icon name="note-added" className={classes.hasNoteItems} />
                        <Typography className={classes.detailFigure}>{noteCount}</Typography>
                        <Typography className={classes.detailCaption}>
                          <Plural value={noteCount} one="Note added" other="Notes added" />
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}

export { ProcessHeader }
