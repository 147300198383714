import React, { useState, useEffect } from "react"
import { Box, Grid, TextField, MenuItem, Divider, Typography } from "@material-ui/core"
import { CodeOutlined, EmailOutlined } from "@material-ui/icons"
import moment from "moment"
import Validator from "@impelsys/validatorjs"
import { useApolloClient } from "@apollo/client"
import { Trans, t } from "@lingui/macro"
import { CreatorActions, CreatorMaster } from "../Creators"
import { UserPanel } from "../UserPanel"
import { useFormUtils, toId, useAnalytics } from "../../utils"
import { FieldSectionHeading } from "../Headings"
import {
  useQueryGroups,
  useMutationUpdateUser,
  useMutationCreateUser,
  USER_STATUS,
  useMutationCreateExternalUser,
  useMutationResendInvite,
} from "../../data"
import { useAuth } from "../../services"
import {
  TileButton,
  ColumnBox,
  RowBox,
  OutlinedSelect,
  GroupCreator,
  useSnackbar,
  IdentityProviderOutlinedSelect,
  LocationOutlinedSelect,
  PinInput,
} from ".."
import { LinkButton } from "../LinkButton"
import { FormatDateTimeCompact } from "../Format"
import TimeAgo from "../TimeAgo"
import { TrainingOutlinedSelect } from "../Training"
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"
import { GroupOutlinedSelectWithCreator } from "../OutlinedSelect/GroupOutlinedSelectWithCreator"
import EmailChangeDialog from "../../areas/account/Profile/EmailChangeDialog"
import { useMutationAdminableUserCancelUsernameChange } from "../../data/users/useMutationAdminableUserCancelUsernameChange"
import EmailChangeButton from "../../areas/account/Profile/EmailChangeButton"
import { useMutationAdminableUserResendUsernameChange } from "../../data/users/useMutationAdminableUserResendUsernameChange"
import SuccessfulEmailChangeDialog from "../../areas/account/Profile/SuccessfulEmailChangeDialog"

const ExternalSwitcher = ({ external, onChange, variant = "single" }) => {
  const handleChange = (value) => {
    onChange && onChange(value)
  }

  const isPlural = variant === "plural"

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ColumnBox flexGrow={1}>
              <TileButton
                title={isPlural ? <Trans>Local users</Trans> : <Trans>Local user</Trans>}
                description={
                  isPlural ? (
                    <Trans>
                      The users will be invited by email and will sign in using an email address and password / PIN
                    </Trans>
                  ) : (
                    <Trans>
                      The user will be invited by email and will sign in using an email address and password / PIN
                    </Trans>
                  )
                }
                active={!external}
                onClick={() => handleChange(false)}
                style={{ width: "100%" }}
                data-cy="TileButton-internal"
              />
            </ColumnBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TileButton
              title={isPlural ? <Trans>Single sign-on users</Trans> : <Trans>Single sign-on user</Trans>}
              description={
                isPlural ? (
                  <Trans>
                    Prepare settings for users that will sign in using your single sign-on identity provider
                  </Trans>
                ) : (
                  <Trans>
                    Prepare settings for a user that will sign in using your single sign-on identity provider
                  </Trans>
                )
              }
              active={external}
              onClick={() => handleChange(true)}
              style={{ width: "100%" }}
              data-cy="TileButton-external"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
    </>
  )
}

const PersonCreator = ({ edit, onClose, isInline, onRefetch }) => {
  const { isValid, debounceTouched } = useFormUtils()
  const analytics = useAnalytics()
  const { isCurrentUser, hasFeature, hasPermission, refresh } = useAuth()
  const client = useApolloClient()
  const snackbar = useSnackbar()

  const [updateUser, { loading: updateUserLoading }] = useMutationUpdateUser()
  const [createUser, { loading: createUserLoading }] = useMutationCreateUser()
  const [createExternalUser, { loading: createExternalUserLoading }] = useMutationCreateExternalUser()
  const [resendInvite, { loading: sendInviteLoading }] = useMutationResendInvite()
  const { data: groupsData, refetch: refetchGroups } = useQueryGroups()
  const [cancelUsernameChange, { loading: cancelUsernameChangeLoading }] =
    useMutationAdminableUserCancelUsernameChange()
  const [resendUsernameChange, { loading: resendUsernameChangeLoading }] =
    useMutationAdminableUserResendUsernameChange()

  const loading = !groupsData
  const [touched, setTouched] = useState(false)
  const [external, setExternal] = useState(false)
  const [hasEmail, setHasEmail] = useState(false)
  const [identityProvider, setIdentityProvider] = useState("")
  const [unique, setUnique] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [groups, setGroups] = useState([])
  const [locations, setLocations] = useState([])
  const [trainingCourses, setTrainingCourses] = useState([])
  const [roles, setRoles] = useState("")
  const [status, setStatus] = useState(false)
  const [isPendingUsernameChange, setIsPendingUsernameChange] = useState(false)
  const [pin, setPin] = useState("")

  const [emailTouched, setEmailTouched] = useState(false)
  const [emailValid, setEmailValid] = useState(false)

  const [hasCourses, setHasCourses] = useState(true)
  const [openCreateGroup, setOpenCreateGroup] = useState(false)

  const [openEmailChange, setOpenEmailChange] = useState(false)
  const [openSuccessfulDialog, setOpenSuccessfulDialog] = useState(false)

  const currentUser = edit && isCurrentUser(edit.id)

  const canChangeStatus = edit && edit.status !== USER_STATUS.INITIAL

  const isInitial = edit && USER_STATUS.INITIAL === edit.status
  const isInvite = edit && [USER_STATUS.INVITED, USER_STATUS.INITIAL].includes(edit.status)
  const isInviteExpired = isInvite && moment().isAfter(moment(edit.invite.expiresAt))
  const canChangeEmail = !edit || (isInvite && !external)

  const activated = edit && edit.invite.activatedAt !== null

  useEffect(() => {
    if (edit) {
      setIdentityProvider(edit.external ? toId(edit.identityProvider) : "")
      setUnique(edit.unique)
      setFirstName(edit.firstName)
      setLastName(edit.lastName)
      setEmail(edit.email)
      setHasEmail(!edit.external || (edit.external && !edit.email.endsWith(toId(edit.identityProvider))))
      setEmailValid(new Validator({ value: edit.email }, { value: "required|email" }).passes())
      setGroups(edit.groups.map((item) => toId(item)))
      setLocations(edit.locations.map((item) => toId(item)))
      setRoles(edit.roles.length ? toId(edit.roles[0]) : "user")
      setStatus(edit.status)
      setExternal(edit.external)
      if (edit?.usernameChange) {
        const { sentAt, expiresAt } = edit.usernameChange
        const isExpired = moment().isAfter(expiresAt)
        setIsPendingUsernameChange(sentAt && !isExpired)
      }
    }
  }, [edit])

  const handleClose = (event, isCancel) => {
    onClose(isCancel)
  }

  const handleChange = (set, oldValue, newValue) => {
    if (newValue !== oldValue) {
      if (!touched) setTouched(true)
      set(newValue)
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    setEmailValid(new Validator({ value }, { value: "required|email" }).passes())
  }

  const handleChangeMultiple = (set, value) => {
    if (!touched) setTouched(true)
    set(value.filter((item) => item !== "new"))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formValid()) {
      let result
      if (edit) {
        result = await updateUser({
          variables: {
            id: edit.id,
            input: {
              firstName,
              lastName,
              ...(canChangeEmail && { email }),
              groups,
              locations,
              roles: roles === "user" ? [] : [roles],
              status,
            },
          },
        })
        // is me?
        if (isCurrentUser(edit.id)) {
          refresh(client, false)
        }
      } else if (external) {
        result = await createExternalUser({
          variables: {
            input: {
              identityProvider,
              unique,
              firstName,
              lastName,
              groups,
              locations,
              trainingCourses,
              roles: roles === "user" ? [] : [roles],
              temporaryPin: pin || undefined,
            },
          },
        })
        analytics.track("Created User")
      } else {
        result = await createUser({
          variables: {
            input: {
              firstName,
              lastName,
              email,
              groups,
              locations,
              trainingCourses,
              roles: roles === "user" ? [] : [roles],
              invite: true,
            },
          },
        })
        analytics.track("Created User")
      }
      if (result.errors?.length) {
        snackbar.showMessage({ message: result.errors[0].message, icon: <CodeOutlined />, color: "secondary" })
        return
      }
      handleClose()
    }
  }

  const handleResendInvite = () => {
    resendInvite({ variables: { id: toId(edit) } })
  }

  const handleCoursesDataLoaded = (coursesData) => {
    setHasCourses(Boolean(coursesData?.length))
  }

  const handleCreateGroupClose = async (updated) => {
    setOpenCreateGroup(false)
    if (updated) await refetchGroups()
  }

  const handleRegionChange = (newLocations) => {
    handleChangeMultiple(setLocations, newLocations)
  }

  const externalEdit = edit && external

  const createExternal = !edit && external

  const formLocalValid = () =>
    locations.length && isValid(firstName, lastName, email, roles) && (external || emailValid)

  const formCreateExternalValid = () =>
    locations.length && isValid(identityProvider, unique, firstName, lastName, roles)

  const formValid = () => (createExternal ? formCreateExternalValid() : formLocalValid())

  const isFormValid = formValid()

  const hasFeatureIdentityProviders = hasFeature("identity_providers")

  // Use this temporarily to disable complexity check for customers that want it
  const hasUserPinVisible = hasFeature("user_pin_visible")

  const canEditSelf = !edit || !currentUser || hasPermission("user_update_all")

  const handleCloseChangeEmailDialog = () => {
    setOpenEmailChange(false)
  }

  const handleOpenEmailChangeDialog = () => {
    setOpenEmailChange(true)
  }

  const handleChangeEmailSuccessful = () => {
    setIsPendingUsernameChange(true)
  }

  const handleCancelUsernameChange = async () => {
    try {
      await cancelUsernameChange({
        variables: {
          id: toId(edit),
        },
      })
      setIsPendingUsernameChange(false)
      if (onRefetch) onRefetch()
      snackbar.showMessage({ message: t`Your email address change has been cancelled.`, icon: <EmailOutlined /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
  }

  const handleResendVerification = async () => {
    const resp = await resendUsernameChange({
      variables: {
        id: toId(edit),
      },
    })
    if (!resp?.errors?.length) {
      setOpenSuccessfulDialog(true)
      if (onRefetch) onRefetch()
    }
  }

  const form = (
    <>
      <GroupCreator open={openCreateGroup} onClose={handleCreateGroupClose} />

      {!edit && hasFeatureIdentityProviders && <ExternalSwitcher external={external} onChange={setExternal} />}

      {edit && <UserPanel user={edit} layout="landscape" mb={4} mr={0} />}

      <Box mb={3}>
        <Grid container spacing={2}>
          {external && (
            <>
              <Grid item xs={12}>
                <IdentityProviderOutlinedSelect
                  value={identityProvider}
                  onChange={(event) => handleChange(setIdentityProvider, identityProvider, event.target.value)}
                  disabled={loading || !!edit}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t`User ID`}
                  value={unique}
                  onChange={(event) => handleChange(setUnique, unique, event.target.value)}
                  required
                  disabled={loading || !!edit}
                  inputProps={{ "data-cy": "TextField-unique" }}
                />
                <Box mt={0.25} mx={1.75}>
                  <small>
                    <Trans>
                      User ID is the unique identifier assigned by your identity provider. Often called User ID or
                      Object ID
                    </Trans>{" "}
                    (Azure AD).
                    <br />
                    <Trans>See your identity providers documentation for further guidance.</Trans>
                  </small>
                </Box>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label={t`First name`}
              value={firstName}
              onChange={(event) => handleChange(setFirstName, firstName, event.target.value)}
              required
              disabled={loading || externalEdit}
              inputProps={{ "data-cy": "TextField-firstname" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label={t`Last name`}
              value={lastName}
              onChange={(event) => handleChange(setLastName, lastName, event.target.value)}
              required
              disabled={loading || externalEdit}
              inputProps={{ "data-cy": "TextField-lastname" }}
            />
          </Grid>
          {!createExternal && (!edit || hasEmail) && (
            <Grid item xs={12}>
              <Box mb={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t`Email address`}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => debounceTouched(setEmailTouched)}
                  error={emailTouched && !emailValid}
                  required
                  disabled={loading || !canChangeEmail}
                  inputProps={{ "data-cy": "TextField-email" }}
                />
              </Box>
              {!!edit && (
                <>
                  {!canChangeEmail && !externalEdit && (
                    <>
                      <EmailChangeButton
                        isPendingUsernameChange={isPendingUsernameChange}
                        onChangeEmailAddress={handleOpenEmailChangeDialog}
                        onCancelEmailAddressChange={handleCancelUsernameChange}
                        onResendVerification={handleResendVerification}
                        isLoading={cancelUsernameChangeLoading || resendUsernameChangeLoading}
                      />
                    </>
                  )}
                  {externalEdit && (
                    <RowBox>
                      <Box ml="auto">
                        <small>This user is managed externally, editing is disabled for some fields</small>
                      </Box>
                    </RowBox>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <FieldSectionHeading size="large">
        <Trans>Work &amp; location</Trans>
      </FieldSectionHeading>

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} id="areas-of-work-grid-item">
            <GroupOutlinedSelectWithCreator
              id="areas-of-work"
              label={t`Areas of work`}
              value={groups}
              multiple
              onChange={(event) => handleChangeMultiple(setGroups, event.target.value)}
              disabled={loading || !canEditSelf}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationOutlinedSelect
              mode="admin"
              value={locations}
              onChange={(event) => handleChangeMultiple(setLocations, event.target.value)}
              onRegionChange={handleRegionChange}
              hasAll={false}
              multiple
              required
              disabled={loading || !canEditSelf}
            />
          </Grid>
          {!edit && hasCourses && (
            <Grid item xs={12}>
              <TrainingOutlinedSelect
                label={t`Training courses`}
                value={trainingCourses}
                onChange={(event) => handleChangeMultiple(setTrainingCourses, event.target.value)}
                onDataLoaded={handleCoursesDataLoaded}
                hasNone={false}
                multiple
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <FieldSectionHeading size="large">
        <Trans>Permissions</Trans> {edit && <>&amp; status</>}
      </FieldSectionHeading>

      <Box mb={isInvite ? 1 : 3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={edit && canChangeStatus ? 6 : 12}>
            <RoleOutlinedSelect
              value={roles}
              onChange={(event) => handleChange(setRoles, roles, event.target.value)}
              disabled={currentUser}
              required
            />
          </Grid>

          {canChangeStatus && (
            <Grid item xs={12} sm={6}>
              <OutlinedSelect
                label={t`Status`}
                id="status"
                native={false}
                value={status}
                onChange={(event) => handleChange(setStatus, status, event.target.value)}
                disabled={currentUser}
                menuPortal={false}
                data-cy="OutlinedSelect-status"
              >
                {[USER_STATUS.ACTIVE, USER_STATUS.INACTIVE].includes(edit.status) && activated && (
                  <MenuItem value={USER_STATUS.ACTIVE}>Active</MenuItem>
                )}
                {[USER_STATUS.INVITED, USER_STATUS.INACTIVE].includes(edit.status) &&
                  !activated &&
                  !isInviteExpired && <MenuItem value={USER_STATUS.INVITED}>Invited</MenuItem>}
                {isInviteExpired && <MenuItem value={USER_STATUS.INVITED}>Expired</MenuItem>}
                <MenuItem value={USER_STATUS.INACTIVE}>Inactive</MenuItem>
              </OutlinedSelect>
            </Grid>
          )}

          {createExternal && (
            <Grid item xs={12}>
              <ColumnBox gap={1}>
                <Box>
                  <FieldSectionHeading size="small" mb={0}>
                    <Trans>Set temporary PIN</Trans>
                  </FieldSectionHeading>
                  <Typography variant="caption">
                    This PIN will be used to access the tablet app. User will be required to set a new PIN on first
                    login.
                  </Typography>
                </Box>
                <PinInput
                  length={4}
                  onChange={(value) => handleChange(setPin, pin, value)}
                  password
                  disableComplexityCheck={hasUserPinVisible}
                  allowShow
                />
              </ColumnBox>
            </Grid>
          )}
        </Grid>
      </Box>

      {isInvite && (
        <RowBox mb={3} justifyContent="flex-end">
          {isInviteExpired && (
            <Box mr={1}>
              Invite expired <FormatDateTimeCompact value={edit.invite.expiresAt} />
            </Box>
          )}
          {!isInviteExpired && Boolean(edit.invite?.sentAt) && (
            <Box mr={1}>
              Invite sent <TimeAgo date={edit.invite.sentAt} lowercase />, expires{" "}
              <FormatDateTimeCompact value={edit.invite.expiresAt} />
            </Box>
          )}
          <LinkButton onClick={handleResendInvite} disabled={sendInviteLoading}>
            {isInitial && <>Send invite</>}
            {!isInitial && <>Resend invite</>}
          </LinkButton>
        </RowBox>
      )}

      <CreatorActions
        id="PersonCreator-CreatorActions"
        subject={t`Person`}
        cancelLabel={edit ? t`Back to profile` : t`Cancel`}
        submitLabel={edit || createExternal ? null : t`Send invitation`}
        submitLoading={createUserLoading || createExternalUserLoading || updateUserLoading}
        onClose={(event) => handleClose(event, true)}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  const title = edit ? t`Edit staff details` : t`Add single person`

  return (
    <>
      <SuccessfulEmailChangeDialog
        open={openSuccessfulDialog}
        onClose={() => setOpenSuccessfulDialog(false)}
        user={edit}
      />
      <EmailChangeDialog
        userId={toId(edit)}
        open={openEmailChange}
        onClose={handleCloseChangeEmailDialog}
        onSuccess={handleChangeEmailSuccessful}
        user={edit}
      />
      <CreatorMaster open title={title} form={form} isInline={isInline} onClose={handleClose} />
    </>
  )
}

export { PersonCreator, ExternalSwitcher as PersonCreatorExternalSwitcher }
