import React, { useContext } from "react"
import { SvgIcon, Icon as MuiIcon, useTheme } from "@material-ui/core"
import {
  AccountCircle,
  AccountCircleOutlined,
  DescriptionOutlined,
  PlaylistAddCheckOutlined,
  NotificationsOutlined,
  Search,
  FormatListNumbered,
  PeopleOutline,
  FeedbackOutlined,
  AccessTime,
  Repeat,
  Public,
  Category,
  InfoOutlined,
  Image,
  CloudUpload,
  ListAlt,
  PersonOutline,
  VisibilityOutlined,
  Star,
  AspectRatio,
  Alarm,
  ArrowBack,
  Warning,
  Android,
  Apple,
  EditOutlined,
  FileCopyOutlined,
  HelpOutline,
  RestorePageOutlined,
  AssignmentIndOutlined,
  ArrowDownward,
  TabletMacOutlined,
  TabletAndroidOutlined,
  PhoneAndroidOutlined,
  PhoneIphoneOutlined,
  ComputerOutlined,
  DesktopMacOutlined,
  TvOutlined,
  PinDropOutlined,
  Phonelink,
  UpdateOutlined,
  SettingsApplicationsOutlined,
  PhonelinkSetupOutlined,
  BatteryAlert,
  Battery30,
  Battery20,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar,
  Refresh,
  Redo,
  FlagOutlined,
  Undo,
  CameraAltOutlined,
  AddPhotoAlternateOutlined,
  Notes,
  ArrowForward,
  Flag,
  SettingsRemoteOutlined,
  BallotOutlined,
  MoreHoriz,
  BatteryFull,
  PictureAsPdfOutlined,
  SchoolOutlined,
  MenuBookOutlined,
  ChevronRight,
  Check,
  ArrowUpward,
  DragIndicator,
  DeleteOutline,
  Remove,
  Close,
  Visibility,
  List,
  CodeOutlined,
  OpenInNewOutlined,
  MailOutline,
  Loop,
  PublishOutlined,
  AttachFile,
  ExpandMore,
  Block,
  LinkOff,
  Room,
  ExtensionOutlined,
  SettingsOutlined,
  SyncAlt,
  MessageOutlined,
  LockOutlined,
  Done,
  StarOutline,
  SendOutlined,
  Schedule,
  Fullscreen,
  FullscreenExit,
  SortByAlpha,
  Sort,
  SystemUpdateAlt,
  OfflineBoltOutlined,
  Add,
  ExpandLess,
  PauseCircleOutline,
  PlayCircleOutline,
  Bluetooth,
  ErrorOutline,
  PrintOutlined,
  PrintDisabledOutlined,
  PhoneOutlined,
  CheckBoxOutlined,
  TitleOutlined,
  SpeedOutlined,
  ShortText,
  ScreenRotationOutlined,
  ExpandMoreOutlined,
  MobileOffOutlined,
  GroupAddOutlined,
  MapOutlined,
  SubdirectoryArrowRight,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  EmojiObjectsOutlined,
  Announcement,
  BusinessOutlined,
  AssignmentOutlined,
  LocalOfferOutlined,
  ReportProblemOutlined,
  TodayOutlined,
  LockOpenOutlined,
  VisibilityOffOutlined,
  Brightness1,
  VerifiedUserOutlined,
  RestaurantMenuOutlined,
  TimerOutlined,
  UnfoldMoreOutlined,
  UnfoldLessOutlined,
  Tune,
  PermMediaOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  Usb,
  Wifi,
  PhonelinkLockOutlined,
} from "@material-ui/icons"
import { ThemeContext } from "../../ThemeContext.js"
import { Logo } from ".."

// Below are custom icons not part of the MUI icon set

// Area icons, used in the area header and in the mobile nav
import SvgHomeDark from "./svg/icon-home-dark.js"
import SvgJobsDark from "./svg/icon-jobs-dark.js"
import SvgKnowledgeDark from "./svg/icon-kb-dark.js"
import SvgCommsDark from "./svg/icon-comms-dark.js"
import SvgLabelsDark from "./svg/icon-labels-dark.js"
import SvgPeopleDark from "./svg/icon-people-dark.js"
import SvgSettingsDark from "./svg/icon-settings-dark.js"
import SvgTemplatesDark from "./svg/icon-templates-dark.js"
import SvgSensorsDark from "./svg/icon-sensors-dark.js"
import SvgFoodItemsDark from "./svg/icon-food-items-dark.js"
import SvgFoodSafetyDark from "./svg/icon-food-safety-dark.js"
import SvgReportsDark from "./svg/icon-reports-dark.js"
import SvgTimeclockDark from "./svg/icon-timeclock-dark.js"
import SvgTimersDark from "./svg/icon-timers-dark.js"
import SvgTimerClock from "./svg/icon-timer-clock.js"
import SvgTrainingAccreditationDark from "./svg/icon-training-accreditation-dark.js"
import SvgAuditsDark from "./svg/icon-audits-dark.js"
import SvgManagersHubDark from "./svg/icon-managers-hub-dark.js"
import SvgTabletsMobileDark from "./svg/icon-tablets-mobile-dark.js"
import SvgTriggersDark from "./svg/icon-triggers-dark.js"
import SvgActionsDark from "./svg/icon-actions-dark.js"
import SvgSuppliersDark from "./svg/icon-suppliers-dark.js"
import SvgAssetsDark from "./svg/icon-assets-dark.js"
import SvgCalendarDark from "./svg/icon-calendar-dark.js"
import SvgNotificationsDark from "./svg/icon-notifications-dark.js"
import SvgSearchDark from "./svg/icon-search-dark.js"
import SvgViewDefaultDark from "./svg/icon-view-default-dark.js"
import SvgViewChildcareDark from "./svg/icon-view-childcare-dark.js"
import SvgViewHealthSafetyDark from "./svg/icon-view-healthsafety-dark.js"
import SvgViewHotelsDark from "./svg/icon-view-hotels-dark.js"
import SvgViewHRDark from "./svg/icon-view-hr-dark.js"
import SvgViewInspectDark from "./svg/icon-view-inspect-dark.js"
import SvgViewManufacturingDark from "./svg/icon-view-manufacturing-dark.js"
import SvgViewFitnessDark from "./svg/icon-view-fitness-dark.js"
import SvgViewRetailDark from "./svg/icon-view-retail-dark.js"
import SvgViewTargetDark from "./svg/icon-view-target-dark.js"
import SvgViewSectionDark from "./svg/icon-view-section-dark.js"
import SvgMoreDark from "./svg/icon-more-dark.js"
import SvgPrepDark from "./svg/icon-prep-dark.js"
import SvgDownloadsDark from "./svg/icon-downloads-dark.js"

// Empty icons that are displayed when no data is available for a component
import SvgEmptyActiveJobs from "./svg/icon-empty-activejobs.svg"
import SvgEmptyActivity from "./svg/icon-empty-activity.svg"
import SvgEmptyAssignedJobs from "./svg/icon-empty-assignedjobs.svg"
import SvgEmptyComms from "./svg/icon-empty-comms.svg"
import SvgEmptyKnowledge from "./svg/icon-empty-kb.svg"
import SvgEmptySearch from "./svg/icon-empty-search.svg"
import SvgEmptyTemplates from "./svg/icon-empty-templates.svg"
import SvgEmptyLabels from "./svg/icon-empty-labels.svg"
import SvgEmptyLabelTemplates from "./svg/icon-empty-label-templates.svg"

// Banner icons used in onboarding cards
import SvgBannersComms from "./svg/icon-ob-comms.svg"
import SvgBannersKnowledge from "./svg/icon-ob-kb.svg"
import SvgBannersPeople from "./svg/icon-ob-people.svg"
import SvgBannersTemplates from "./svg/icon-ob-templates.svg"

// Additional MUI-like icons. These are not part of the official MUI icon set, but are used in the same way
import { ReactComponent as SvgPositiveNegative } from "./svg/icon-positive-negative.svg"
import { ReactComponent as SvgNoteMedia } from "./svg/icon-notemedia.svg"
import { ReactComponent as SvgNoteMediaAdded } from "./svg/icon-notemedia-added.svg"
import { ReactComponent as SvgBulletin } from "./svg/icon-bulletin.svg"
import { ReactComponent as SvgLabelsTemplate } from "./svg/icon-labels-template.svg"
import { ReactComponent as SvgLabelsItem } from "./svg/icon-labels-item.svg"
import { ReactComponent as SvgUpperCase } from "./svg/icon-uppercase.svg"
import { ReactComponent as SvgSentenceCase } from "./svg/icon-sentencecase.svg"
import { ReactComponent as SvgNoWrap } from "./svg/icon-nowrap.svg"
import { ReactComponent as SvgPin } from "./svg/icon-pin.svg"
import { ReactComponent as SvgAdhoc } from "./svg/icon-adhoc.svg"
import { ReactComponent as SvgSelection } from "./svg/icon-selection.svg"
import { ReactComponent as SvgProbe } from "./svg/icon-probe.svg"
import { ReactComponent as SvgAudit } from "./svg/icon-audit.svg"
import { ReactComponent as SvgOverdue } from "./svg/icon-overdue.svg"
import { ReactComponent as SvgJob } from "./svg/icon-job.svg"
import { ReactComponent as SvgPriorityLow } from "./svg/icon-priority-low.svg"
import { ReactComponent as SvgPriorityNormal } from "./svg/icon-priority-normal.svg"
import { ReactComponent as SvgPriorityHigh } from "./svg/icon-priority-high.svg"
import { ReactComponent as SvgActionResolved } from "./svg/icon-action-resolved.svg"
import { ReactComponent as SvgSupplier } from "./svg/icon-supplier.svg"
import { ReactComponent as SvgAsset } from "./svg/icon-asset.svg"
import { ReactComponent as SvgVeryDissatisfied } from "./svg/icon-very-dissatisfied.svg"
import { ReactComponent as SvgDissatisfied } from "./svg/icon-dissatisfied.svg"
import { ReactComponent as SvgNeutral } from "./svg/icon-neutral.svg"
import { ReactComponent as SvgSatisfied } from "./svg/icon-satisfied.svg"
import { ReactComponent as SvgVerySatisfied } from "./svg/icon-very-satisfied.svg"
import { ReactComponent as SvgBarcode } from "./svg/icon-barcode.svg"
import { ReactComponent as SvgBluetoothLE } from "./svg/icon-bluetooth-le.svg"
import { ReactComponent as SvgOffset } from "./svg/icon-offset.svg"

// Uploaded file type icons
import { ReactComponent as SvgUploadPDF } from "./svg/icon-upload-pdf.svg"
import { ReactComponent as SvgUploadDOC } from "./svg/icon-upload-doc.svg"
import { ReactComponent as SvgUploadODT } from "./svg/icon-upload-odt.svg"
import { ReactComponent as SvgUploadPAGES } from "./svg/icon-upload-pages.svg"
import { ReactComponent as SvgUploadXLS } from "./svg/icon-upload-xls.svg"
import { ReactComponent as SvgUploadODS } from "./svg/icon-upload-ods.svg"
import { ReactComponent as SvgUploadZIP } from "./svg/icon-upload-zip.svg"
import { ReactComponent as SvgDownloadReport } from "./svg/icon-download-report.svg"

// Locale icons
import { ReactComponent as SvgLocaleEN_US } from "./svg/icon-locale-en-US.svg"
import { ReactComponent as SvgLocaleJA_JP } from "./svg/icon-locale-ja-JP.svg"
import { ReactComponent as SvgLocaleES_ES } from "./svg/icon-locale-es-ES.svg"
import { ReactComponent as SvgLocaleKO_KR } from "./svg/icon-locale-ko-KR.svg"

import SvgSuccess from "./svg/icon-success.svg"
import SvgAuditSubmitted from "./svg/icon-audit-submitted.svg"

// Social icons
import FacebookLogo from "./png/fb.png"
import GoogleLogo from "./png/google.png"
import MicrosoftLogo from "./png/microsoft.png"

// Placeholder icons
import SvgPlaceholder from "./svg/icon-placeholder.svg"

const getClassNameWithMaterialOutlined = (className) => `material-icons-outlined ${className || ""}`.trim()

// Modifies area icons if required with custom theming
const IconThemer = ({ icon: CustomIcon, ...rest }) => {
  const theme = useTheme()
  const { customSecondaryColor } = useContext(ThemeContext)
  let primary
  let secondary

  // Custom icons use black primary and the secondary color as accent
  if (theme.hasCustomColors) {
    primary = theme.palette.common.black
    secondary = customSecondaryColor || ""
  }

  // Check to apply inverted icons in AreaHeader when using default theme
  if (rest?.reversed === true) {
    primary = theme.palette.common.white
    secondary = ""
  }

  // Pass through to SVG, use hardcoded fallbacks in file if not supplied
  const iconProps = {}
  if (primary) {
    iconProps.primary = primary
  }
  if (secondary) {
    iconProps.secondary = secondary
  }

  return (
    <div style={{ display: "inline-flex" }} {...rest}>
      <CustomIcon {...iconProps} />
    </div>
  )
}

const Icon = ({ name, customName, throwForUnmapped = true, ...rest }) => {
  switch (`${name}`.toLowerCase()) {
    case "operandio":
      return <Logo {...rest} />
    case "account-circle":
      return <AccountCircle {...rest} />
    case "account-circle-alt":
      return <AccountCircleOutlined {...rest} />
    case "group":
    case "integration-group":
      return <PeopleOutline {...rest} />
    case "role":
    case "integration-role":
      return <VerifiedUserOutlined {...rest} />
    case "group-visibility-general":
      return <GroupAddOutlined {...rest} />
    case "everyone":
      return <Public {...rest} />
    case "identity-provider":
      return <AssignmentIndOutlined {...rest} />
    case "integration":
      return <ExtensionOutlined {...rest} />
    case "integration-mapping":
      return <SyncAlt {...rest} />
    case "integration-event":
      return (
        <MuiIcon {...rest} className="material-icons-outlined">
          send_time_extension
        </MuiIcon>
      )
    case "integration-location":
      return (
        <MuiIcon {...rest} className="material-icons-outlined">
          where_to_vote
        </MuiIcon>
      )
    case "integration-user":
      return (
        <MuiIcon {...rest} className="material-icons-outlined">
          person_pin
        </MuiIcon>
      )
    case "trigger":
      return <SendOutlined {...rest} />
    case "trigger-scheduled":
      return <Schedule {...rest} />
    case "trigger-event":
      return <SendOutlined {...rest} />
    case "location":
      return <PinDropOutlined {...rest} />
    case "location-pin":
      return <Room {...rest} />
    case "region":
      return <MapOutlined {...rest} />
    case "timezone":
      return <Public {...rest} />
    case "clock":
      return <Schedule {...rest} />
    case "setting":
      return <SettingsApplicationsOutlined {...rest} />
    case "configure":
      return <SettingsOutlined {...rest} />
    case "filters":
      return <Tune {...rest} />
    case "tag":
      return <LocalOfferOutlined {...rest} />
    case "report-problem":
      return <ReportProblemOutlined {...rest} />
    case "dashboard-dark":
      return <IconThemer icon={SvgHomeDark} {...rest} />
    case "jobs-dark":
      return <IconThemer icon={SvgJobsDark} {...rest} />
    case "knowledge-dark":
      return <IconThemer icon={SvgKnowledgeDark} {...rest} />
    case "hub-dark":
      return <IconThemer icon={SvgCommsDark} {...rest} />
    case "labels-dark":
      return <IconThemer icon={SvgLabelsDark} {...rest} />
    case "templates-dark":
      return <IconThemer icon={SvgTemplatesDark} {...rest} />
    case "people-dark":
      return <IconThemer icon={SvgPeopleDark} {...rest} />
    case "sensors-dark":
      return <IconThemer icon={SvgSensorsDark} {...rest} />
    case "reports-dark":
      return <IconThemer icon={SvgReportsDark} {...rest} />
    case "settings-dark":
      return <IconThemer icon={SvgSettingsDark} {...rest} />
    case "timeclock-dark":
      return <IconThemer icon={SvgTimeclockDark} {...rest} />
    case "timers-dark":
      return <IconThemer icon={SvgTimersDark} {...rest} />
    case "training-accreditation-dark":
      return <IconThemer icon={SvgTrainingAccreditationDark} {...rest} />
    case "audits-dark":
      return <IconThemer icon={SvgAuditsDark} {...rest} />
    case "managers-hub-dark":
      return <IconThemer icon={SvgManagersHubDark} {...rest} />
    case "tablets-mobile-dark":
      return <IconThemer icon={SvgTabletsMobileDark} {...rest} />
    case "triggers-dark":
      return <IconThemer icon={SvgTriggersDark} {...rest} />
    case "actions-dark":
      return <IconThemer icon={SvgActionsDark} {...rest} />
    case "suppliers-dark":
      return <IconThemer icon={SvgSuppliersDark} {...rest} />
    case "assets-dark":
      return <IconThemer icon={SvgAssetsDark} {...rest} />
    case "calendar-dark":
      return <IconThemer icon={SvgCalendarDark} {...rest} />
    case "notifications-dark":
      return <IconThemer icon={SvgNotificationsDark} {...rest} />
    case "search-dark":
      return <IconThemer icon={SvgSearchDark} {...rest} />
    case "prep-dark":
      return <IconThemer icon={SvgPrepDark} {...rest} />
    case "view-default-dark":
      return <IconThemer icon={SvgViewDefaultDark} {...rest} />
    case "food-safety-dark":
      return <IconThemer icon={SvgFoodSafetyDark} {...rest} />
    case "food-items-dark":
      return <IconThemer icon={SvgFoodItemsDark} {...rest} />
    case "view-childcare-dark":
      return <IconThemer icon={SvgViewChildcareDark} {...rest} />
    case "view-healthsafety-dark":
      return <IconThemer icon={SvgViewHealthSafetyDark} {...rest} />
    case "view-hotels-dark":
      return <IconThemer icon={SvgViewHotelsDark} {...rest} />
    case "view-hr-dark":
      return <IconThemer icon={SvgViewHRDark} {...rest} />
    case "view-inspect-dark":
      return <IconThemer icon={SvgViewInspectDark} {...rest} />
    case "view-manufacturing-dark":
      return <IconThemer icon={SvgViewManufacturingDark} {...rest} />
    case "view-fitness-dark":
      return <IconThemer icon={SvgViewFitnessDark} {...rest} />
    case "view-retail-dark":
      return <IconThemer icon={SvgViewRetailDark} {...rest} />
    case "view-target-dark":
      return <IconThemer icon={SvgViewTargetDark} {...rest} />
    case "view-section-dark":
      return <IconThemer icon={SvgViewSectionDark} {...rest} />
    case "more-dark":
      return <IconThemer icon={SvgMoreDark} {...rest} />
    case "downloads-dark":
      return <IconThemer icon={SvgDownloadsDark} {...rest} />

    case "question":
      return <MuiIcon {...rest}>question_mark</MuiIcon>
    case "training":
      return <SchoolOutlined {...rest} />
    case "training-module":
      return <MenuBookOutlined {...rest} />
    case "training-module-complete":
    case "accreditations-compliant":
    case "tick":
    case "checkbox":
      return <Check {...rest} />
    case "checkbox-alt":
      return <CheckBoxOutlined {...rest} />
    case "cross":
    case "clear":
      return <Close {...rest} />
    case "pause":
      return <PauseCircleOutline {...rest} />
    case "unpause":
      return <PlayCircleOutline {...rest} />
    case "accreditations-awaiting":
      return <PublishOutlined {...rest} />
    case "accreditation":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          badge
        </MuiIcon>
      )
    case "accreditation-alt":
      return <AssignmentIndOutlined {...rest} />
    case "adhoc":
      return <SvgIcon component={SvgAdhoc} {...rest} />
    case "task":
      return <SvgIcon component={SvgJob} {...rest} />
    case "process":
      return <SvgIcon component={SvgJob} {...rest} />
    case "steps":
      return <FormatListNumbered {...rest} />
    case "steps-complete":
      return <PlaylistAddCheckOutlined {...rest} />
    case "form":
      return <ListAlt {...rest} />
    case "knowledge":
      return <EmojiObjectsOutlined {...rest} />
    case "article":
      return <DescriptionOutlined {...rest} />
    case "dynamic":
      return <OfflineBoltOutlined {...rest} />
    case "category":
      return <Category {...rest} />
    case "calendar":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          calendar_month_outlined
        </MuiIcon>
      )
    case "calendar-month":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          calendar_view_month
        </MuiIcon>
      )
    case "calendar-week":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          calendar_view_week
        </MuiIcon>
      )
    case "phone-link-lock":
      return <PhonelinkLockOutlined {...rest} />
    case "person":
      return <PersonOutline {...rest} />
    case "people":
      return <PeopleOutline {...rest} />
    case "address":
      return <BusinessOutlined {...rest} />
    case "sensor":
    case "sensor-disabled":
    case "sensors":
      return <SettingsRemoteOutlined {...rest} />

    case "audit":
      return <SvgIcon component={SvgAudit} {...rest} />
    case "food-item":
      return <RestaurantMenuOutlined {...rest} />
    case "timer-clock":
      return <IconThemer icon={SvgTimerClock} {...rest} />
    case "temperature-timer":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          thermostat_auto
        </MuiIcon>
      )
    case "sensors-temperature":
      return <MuiIcon {...rest}>device_thermostat</MuiIcon>
    case "printer":
      return <PrintOutlined {...rest} />
    case "printer-disabled":
      return <PrintDisabledOutlined {...rest} />
    case "templates-menu":
      return <BallotOutlined {...rest} />
    case "post":
      return <FeedbackOutlined {...rest} />
    case "repeat":
      return <Repeat {...rest} />
    case "time":
      return <AccessTime {...rest} />
    case "timer":
      return <TimerOutlined {...rest} />
    case "due":
    case "schedule":
      return <Alarm {...rest} />
    case "date":
      return <TodayOutlined {...rest} />
    case "no-expiry":
      return <Loop {...rest} />
    case "signature":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          draw
        </MuiIcon>
      )
    case "section":
      return <TitleOutlined {...rest} />
    case "instruction":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          article
        </MuiIcon>
      )

    case "pinned":
      return <SvgIcon component={SvgPin} {...rest} />
    case "add":
      return <Add {...rest} />
    case "skip":
      return <Redo {...rest} />
    case "unskip":
      return <Undo {...rest} />
    case "action":
      return <FlagOutlined {...rest} />
    case "action-raised":
      return <Flag {...rest} />
    case "action-resolved":
      return <SvgIcon component={SvgActionResolved} {...rest} />
    case "action-status":
      return <Brightness1 {...rest} />
    case "note":
      return <SvgIcon component={SvgNoteMedia} {...rest} />
    case "note-added":
      return <SvgIcon component={SvgNoteMediaAdded} {...rest} />
    case "notes":
      return <MessageOutlined {...rest} />
    case "done":
      return <Done {...rest} />
    case "text":
      return <ShortText {...rest} />
    case "long-text":
      return <Notes {...rest} />
    case "number":
      return <MuiIcon {...rest}>numbers</MuiIcon>
    case "positive-negative":
      return <SvgIcon component={SvgPositiveNegative} {...rest} />
    case "camera":
      return <CameraAltOutlined {...rest} />
    case "photo":
      return <AddPhotoAlternateOutlined {...rest} />
    case "rating":
      return <StarOutline {...rest} />
    case "button":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          smart_button
        </MuiIcon>
      )

    case "notifications":
      return <NotificationsOutlined {...rest} />
    case "announcement":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          new_releases
        </MuiIcon>
      )
    case "search":
      return <Search {...rest} />
    case "information":
      return <InfoOutlined {...rest} />
    case "error":
      return <ErrorOutline {...rest} />
    case "warning":
      return <Warning {...rest} />
    case "help":
      return <HelpOutline {...rest} />
    case "draft":
      return <RestorePageOutlined {...rest} />
    case "code":
      return <CodeOutlined {...rest} />
    case "update":
      return <SystemUpdateAlt {...rest} />
    case "magic":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          auto_awesome
        </MuiIcon>
      )
    case "logic":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          alt_route
        </MuiIcon>
      )

    case "image":
      return <Image {...rest} />
    case "document":
      return <DescriptionOutlined {...rest} />
    case "pdf":
      return <PictureAsPdfOutlined {...rest} />
    case "upload":
      return <CloudUpload {...rest} />
    case "upload-file":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          upload_file
        </MuiIcon>
      )
    case "attach-file":
      return <AttachFile {...rest} />
    case "custom":
      return <MuiIcon {...rest}>{customName}</MuiIcon>
    case "custom-outlined":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          {customName}
        </MuiIcon>
      )

    case "facebook":
      return <img {...rest} src={FacebookLogo} alt="Facebook" />
    case "google":
      return <img {...rest} src={GoogleLogo} alt="Google" />
    case "microsoft":
      return <img {...rest} src={MicrosoftLogo} alt="Microsoft" />
    case "view":
      return <VisibilityOutlined {...rest} />
    case "view-off":
      return <VisibilityOffOutlined {...rest} />
    case "view-alt":
      return <Visibility {...rest} />
    case "cancel":
      return <Close {...rest} />
    case "confirm":
      return <Check {...rest} />
    case "confirmation-required":
      return <Announcement {...rest} />
    case "back":
      return <ArrowBack {...rest} />
    case "forward":
      return <ArrowForward {...rest} />
    case "up":
      return <ArrowUpward {...rest} />
    case "down":
      return <ArrowDownward {...rest} />
    case "right":
      return <ChevronRight {...rest} />
    case "nested":
      return <SubdirectoryArrowRight {...rest} />
    case "expand":
      return <ExpandMore {...rest} />
    case "collapse":
      return <ExpandLess {...rest} />
    case "copy":
    case "clone":
      return <FileCopyOutlined {...rest} />
    case "clipboard":
      return <AssignmentOutlined {...rest} />
    case "edit":
      return <EditOutlined {...rest} />
    case "download":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          file_download
        </MuiIcon>
      )
    case "download-photos":
      return <PermMediaOutlined {...rest} />
    case "download-failed":
      return <MuiIcon {...rest}>file_download_off</MuiIcon>
    case "download-report":
      return <SvgIcon component={SvgDownloadReport} {...rest} />
    case "zip":
      return (
        <MuiIcon {...rest} className={getClassNameWithMaterialOutlined(rest?.className)}>
          folder_zip
        </MuiIcon>
      )
    case "drag":
    case "drag-active":
      return <DragIndicator {...rest} />
    case "delete":
    case "remove":
      return <DeleteOutline {...rest} />
    case "delete-failed":
      return <Block {...rest} />
    case "not-found":
      return <LinkOff {...rest} />
    case "none":
      return <Remove {...rest} />
    case "external-link":
      return <OpenInNewOutlined {...rest} />
    case "qr-code":
      return <MuiIcon {...rest}>qr_code</MuiIcon>
    case "mail":
    case "email":
      return <MailOutline {...rest} />
    case "phone":
      return <PhoneOutlined {...rest} />
    case "message":
      return <MessageOutlined {...rest} />
    case "restricted":
    case "group-visibility-private":
    case "protect":
      return <LockOutlined {...rest} />
    case "unprotected":
      return <LockOpenOutlined {...rest} />
    case "fullscreen":
      return <Fullscreen {...rest} />
    case "fullscreen-exit":
      return <FullscreenExit {...rest} />
    case "rank":
      return <Sort {...rest} />
    case "sort-by-alpha":
      return <SortByAlpha {...rest} />
    case "score":
      return <SpeedOutlined {...rest} />
    case "featured-star":
      return <FeaturedStar {...rest} />
    case "display-size":
      return <AspectRatio {...rest} />
    case "display-rotation":
      return <ScreenRotationOutlined {...rest} />

    case "android":
      return <Android {...rest} />
    case "android-2":
      return <TabletAndroidOutlined {...rest} />
    case "android-1":
      return <PhoneAndroidOutlined {...rest} />
    case "android-0":
    case "android-3":
      return <ComputerOutlined {...rest} />
    case "android-4":
      return <TvOutlined {...rest} />
    case "ios":
      return <Apple {...rest} />
    case "ios-2":
      return <TabletMacOutlined {...rest} />
    case "ios-1":
      return <PhoneIphoneOutlined {...rest} />
    case "ios-0":
    case "ios-3":
      return <DesktopMacOutlined {...rest} />
    case "ios-4":
      return <TvOutlined {...rest} />

    case "no-devices":
      return <MobileOffOutlined {...rest} />

    case "device-registered":
      return <Phonelink {...rest} />
    case "device-managed":
      return <PhonelinkSetupOutlined {...rest} />
    case "last-activity":
      return <UpdateOutlined {...rest} />
    case "empty-activejobs":
      return <img src={SvgEmptyActiveJobs} {...rest} alt="" />
    case "empty-activity":
      return <img src={SvgEmptyActivity} {...rest} alt="" />
    case "empty-assignedjobs":
      return <img src={SvgEmptyAssignedJobs} {...rest} alt="" />
    case "empty-comms":
      return <img src={SvgEmptyComms} {...rest} alt="" />
    case "empty-knowledge":
    case "empty-trainingmodules":
    case "empty-trainingcourses":
      return <img src={SvgEmptyKnowledge} {...rest} alt="" />
    case "training-complete":
      return <img src={SvgSuccess} {...rest} alt="" />
    case "audit-submitted":
      return <img src={SvgAuditSubmitted} {...rest} alt="" />
    case "empty-search":
      return <img src={SvgEmptySearch} {...rest} alt="" />
    case "empty-templates":
      return <img src={SvgEmptyTemplates} {...rest} alt="" />
    case "empty-labels":
      return <img src={SvgEmptyLabels} {...rest} alt="" />
    case "empty-label-templates":
      return <img src={SvgEmptyLabelTemplates} {...rest} alt="" />
    case "banners-comms":
      return <img src={SvgBannersComms} {...rest} alt="" />
    case "banners-knowledge":
      return <img src={SvgBannersKnowledge} {...rest} alt="" />
    case "banners-people":
      return <img src={SvgBannersPeople} {...rest} alt="" />
    case "banners-templates":
      return <img src={SvgBannersTemplates} {...rest} alt="" />
    case "retry":
      return <Refresh {...rest} />
    case "rate-limit":
      return <MuiIcon {...rest}>network_ping</MuiIcon>
    case "offline":
      return <MuiIcon {...rest}>cloud_off</MuiIcon>
    case "battery0":
    case "battery5":
      return <BatteryAlert {...rest} />
    case "battery10":
    case "battery15":
    case "battery20":
    case "battery25":
      return <Battery20 {...rest} />
    case "battery30":
    case "battery35":
      return <Battery30 {...rest} />
    case "battery40":
    case "battery45":
    case "battery50":
    case "battery55":
      return <Battery50 {...rest} />
    case "battery60":
    case "battery65":
    case "battery70":
    case "battery75":
      return <Battery60 {...rest} />
    case "battery80":
    case "battery85":
      return <Battery80 {...rest} />
    case "battery90":
      return <Battery90 {...rest} />
    case "battery95":
      return <BatteryFull {...rest} />
    case "signal0":
      return <SignalWifi0Bar {...rest} />
    case "signal10":
    case "signal20":
    case "signal30":
      return <SignalWifi1Bar {...rest} />
    case "signal40":
    case "signal50":
      return <SignalWifi2Bar {...rest} />
    case "signal60":
    case "signal70":
      return <SignalWifi3Bar {...rest} />
    case "signal80":
    case "signal90":
    case "signal100":
      return <SignalWifi4Bar {...rest} />
    case "bluetooth":
    case "zebra_bluetooth":
    case "gainscha_bluetooth":
    case "brother_bluetooth":
      return <Bluetooth {...rest} />
    case "bluetooth-low-energy":
      return <SvgIcon component={SvgBluetoothLE} {...rest} />
    case "usb":
      return <Usb {...rest} />
    case "network":
    case "wifi":
      return <Wifi {...rest} />
    case "probe":
      return <SvgIcon component={SvgProbe} {...rest} />
    case "probe-disabled":
      return <SvgIcon component={SvgProbe} {...rest} />
    case "submenu":
      return <ExpandMoreOutlined {...rest} />
    case "more":
      return <MoreHoriz {...rest} />
    case "list":
      return <List {...rest} />
    case "list-alt":
      return <ListAlt {...rest} />
    case "labels-template":
      return <SvgIcon component={SvgLabelsTemplate} {...rest} />
    case "labels-item":
      return <SvgIcon component={SvgLabelsItem} {...rest} />
    case "uppercase":
      return <SvgIcon component={SvgUpperCase} {...rest} />
    case "sentencecase":
      return <SvgIcon component={SvgSentenceCase} {...rest} />
    case "nowrap":
      return <SvgIcon component={SvgNoWrap} {...rest} />
    case "chevron-left":
      return <KeyboardArrowLeftOutlined {...rest} />
    case "chevron-right":
      return <KeyboardArrowRightOutlined {...rest} />
    case "chevron-down":
      return <KeyboardArrowDownOutlined {...rest} />
    case "chevron-up":
      return <KeyboardArrowUpOutlined {...rest} />
    case "priority-low":
      return <SvgIcon component={SvgPriorityLow} {...rest} />
    case "priority-normal":
      return <SvgIcon component={SvgPriorityNormal} {...rest} />
    case "priority-high":
      return <SvgIcon component={SvgPriorityHigh} {...rest} />
    case "priority-critical":
      return <ErrorOutline {...rest} />
    case "bulletin":
      return <SvgIcon component={SvgBulletin} {...rest} />
    case "selection":
      return <SvgIcon component={SvgSelection} {...rest} />
    case "upload-pdf":
      return <SvgIcon component={SvgUploadPDF} {...rest} />
    case "upload-doc":
      return <SvgIcon component={SvgUploadDOC} {...rest} />
    case "upload-odt":
      return <SvgIcon component={SvgUploadODT} {...rest} />
    case "upload-pages":
      return <SvgIcon component={SvgUploadPAGES} {...rest} />
    case "upload-xls":
      return <SvgIcon component={SvgUploadXLS} {...rest} />
    case "upload-ods":
      return <SvgIcon component={SvgUploadODS} {...rest} />
    case "upload-zip":
      return <SvgIcon component={SvgUploadZIP} {...rest} />
    case "placeholder":
      return <img src={SvgPlaceholder} {...rest} alt="" />
    case "very-dissatisfied":
      return <SvgIcon component={SvgVeryDissatisfied} {...rest} />
    case "dissatisfied":
      return <SvgIcon component={SvgDissatisfied} {...rest} />
    case "neutral":
      return <SvgIcon component={SvgNeutral} {...rest} />
    case "satisfied":
      return <SvgIcon component={SvgSatisfied} {...rest} />
    case "very-satisfied":
      return <SvgIcon component={SvgVerySatisfied} {...rest} />
    case "overdue":
      return <SvgIcon component={SvgOverdue} {...rest} />
    case "supplier":
      return <SvgIcon component={SvgSupplier} {...rest} />
    case "asset":
      return <SvgIcon component={SvgAsset} {...rest} />
    case "expand-all":
      return <UnfoldMoreOutlined {...rest} />
    case "collapse-all":
      return <UnfoldLessOutlined {...rest} />
    case "barcode":
      return <SvgIcon component={SvgBarcode} {...rest} />
    case "offset":
      return <SvgIcon component={SvgOffset} {...rest} />

    case "locale-en-us":
      return <SvgIcon component={SvgLocaleEN_US} {...rest} />
    case "locale-ja-jp":
      return <SvgIcon component={SvgLocaleJA_JP} {...rest} />
    case "locale-es-es":
      return <SvgIcon component={SvgLocaleES_ES} {...rest} />
    case "locale-ko-kr":
      return <SvgIcon component={SvgLocaleKO_KR} {...rest} />

    default:
      if (throwForUnmapped) {
        throw new Error(`Unknown icon: ${name}, please map it in the MaterialIcon component`)
      }

      return null
  }
}

const FeaturedStar = ({ ...rest }) => {
  const theme = useTheme()
  return <Star style={{ color: theme.palette.featured.main }} {...rest} />
}

export { Icon }
