import React, { useState } from "react"
import { BottomNavigation, Drawer, List, ListItem, BottomNavigationAction, Hidden, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useLocation } from "react-router-dom"
import { t } from "@lingui/macro"
import { useAuth } from "../../services"
import { useQuerySettings } from "../../data"
import { Icon, SubNavigationListItem } from ".."
import { RequirePermissions } from "../Permissions"
import { useQueryAreas } from "../../data/areas/useQueryAreas"
import { toId } from "../../utils"

const useMobileNavStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 1000,
    bottom: 0,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    height: theme.dimensions.footer.mobileBottomNav.height,
    paddingTop: 5,
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const useMobileNavActionStyles = makeStyles((theme) => ({
  root: {
    padding: "6px 0 !important",
    margin: 0,
    alignItems: "center",
    minWidth: "auto",
  },
  wrapper: {
    height: 51,
  },
  iconOnly: {
    paddingTop: 0,
  },
  selected: {
    "&::after": {
      content: '""',
      height: "3px",
      width: "70%",
      outline: "none",
      background: theme.palette.gradientOrange,
      position: "absolute",
      bottom: 2,
      left: "15%",
      borderRadius: "3px 3px 0px 0px",
    },
  },
}))

const useStyles = makeStyles(() => ({
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
  },
  icon: {
    fontSize: 38,
  },
  drawerIcon: {
    marginRight: "4px",
  },
}))

const MobileBottomNav = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileNavClasses = useMobileNavStyles()
  const mobileNavActionClasses = useMobileNavActionStyles()
  const location = useLocation()
  const {
    hasFeature,
    hasPermission,
    featureDependencies: { prep: prepFeature, timers: timersFeature },
  } = useAuth()
  const {
    authed,
    permissionGroups: { readJobs, readKnowledge, readTemplates },
    location: userLocation,
  } = useAuth()
  const settings = useQuerySettings()
  const { data: areaData } = useQueryAreas({
    variables: {
      filter: {
        locations: [toId(userLocation)],
      },
    },
  })
  const [more, setMore] = useState(false)

  if (!authed) return null

  const handleToggleMore = () => setMore(!more)

  const renderItem = (label, icon, area, to, disabled = false, requires, operator = "or") => {
    const item = (
      <BottomNavigationAction
        label={label}
        showLabel
        to={`/${to}`}
        icon={<Icon name={icon} className={classes.icon} />}
        classes={mobileNavActionClasses}
        selected={location.pathname.startsWith(`/${area}`)}
        value={area}
        component={NavLink}
        style={{ opacity: disabled ? 0.3 : 1 }}
        disabled={disabled}
      />
    )

    return requires ? (
      <RequirePermissions requires={requires} operator={operator}>
        {item}
      </RequirePermissions>
    ) : (
      item
    )
  }

  const renderAreaItems = () => {
    if (!areaData || Object.entries(areaData).length === 0) return null

    return areaData?.areas?.list?.map((area) => {
      const areaUrl = `/area/${area.url}`
      const icon = `${area?.icon ?? "templates"}-dark`

      return (
        <ListItem key={toId(area)}>
          <Icon name={icon} className={`${classes.icon} ${classes.drawerIcon}`} />
          <NavItem to={areaUrl} nested text={area.name} beta />
        </ListItem>
      )
    })
  }

  const NavItem = ({ to, text, ...rest }) => (
    <SubNavigationListItem
      to={to}
      text={text}
      color={theme.palette.text.primary}
      onClick={() => handleToggleMore()}
      {...rest}
    />
  )

  const currentArea = location.pathname.length > 1 ? location.pathname.split("/")[1] : "dashboard"

  const needsMoreMenu =
    hasFeature("labels") ||
    hasFeature("sensors") ||
    hasFeature("suppliers") ||
    hasFeature(prepFeature) ||
    hasFeature(timersFeature)

  return (
    <>
      {authed && (
        <Hidden smUp>
          <Drawer anchor="bottom" open={more} onClose={handleToggleMore} className={classes.drawer}>
            <List>
              {hasFeature("labels") && hasPermission("label_read") && (
                <ListItem>
                  <Icon name="labels-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/labels" nested text={settings.lang.area.labels} />
                </ListItem>
              )}

              {hasPermission(readTemplates) && (
                <ListItem>
                  <Icon name="templates-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/templates" nested text={settings.lang.area.templates} />
                </ListItem>
              )}

              {renderAreaItems()}

              {hasFeature("assets") && hasPermission("asset_read") && (
                <ListItem>
                  <Icon name="assets-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/assets" nested text={settings.lang.area.assets} beta />
                </ListItem>
              )}

              {hasFeature("suppliers") && hasPermission("supplier_read") && (
                <ListItem>
                  <Icon name="suppliers-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/suppliers" nested text={settings.lang.area.suppliers} />
                </ListItem>
              )}

              {hasFeature("food") && hasPermission("food_item_read") && (
                <ListItem>
                  <Icon name="food-items-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/food-items" nested text={settings.lang.area.foodItems} beta />
                </ListItem>
              )}

              {hasFeature(prepFeature) && hasPermission("prep_read") && (
                <ListItem>
                  <Icon name="prep-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/prep" nested text={settings.lang.area.prep} />
                </ListItem>
              )}

              {hasFeature(timersFeature) && hasPermission("timer_read") && (
                <ListItem>
                  <Icon name="timers-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/timers" nested text={settings.lang.area.timers} beta />
                </ListItem>
              )}

              {hasFeature("sensors") && hasPermission("sensor_read") && (
                <ListItem>
                  <Icon name="sensors-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                  <NavItem to="/sensors" nested text={settings.lang.area.sensors} />
                </ListItem>
              )}

              {/* {hasPermission("user_read_admin") && (
                  <ListItem>
                    <Icon name="people-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                    <NavItem to="/people" nested text={settings.lang.area.people} />
                  </ListItem>
                )}  */}
              {/* {hasPermission("user_read_admin") && (
                <ListItem>
                  <Icon name="reports-dark" className={`${classes.icon} ${classes.drawerIcon}`} />
                    <NavItem to="/reports" nested text={settings.lang.area.reports} beta />
                    </ListItem>
                )}  */}
            </List>
          </Drawer>

          <BottomNavigation classes={mobileNavClasses} value={currentArea} showLabels={false}>
            {renderItem(settings.lang.areaShort.dashboard, "dashboard-dark", "dashboard", "dashboard", !userLocation)}
            {renderItem(settings.lang.areaShort.jobs, "jobs-dark", "jobs", "jobs", !userLocation, readJobs)}
            {renderItem(
              settings.lang.areaShort.knowledge,
              "knowledge-dark",
              "knowledge",
              "knowledge",
              false,
              readKnowledge,
            )}
            {renderItem(settings.lang.areaShort.hub, "hub-dark", "hub", "hub", !userLocation, "post_read")}
            {!needsMoreMenu &&
              renderItem(
                settings.lang.areaShort.templates,
                "templates-dark",
                "templates",
                "templates/active-recurring",
                !userLocation,
                readTemplates,
              )}

            {needsMoreMenu && (
              <BottomNavigationAction
                label={t`More`}
                showLabel
                icon={<Icon name="more-dark" className={classes.icon} />}
                classes={mobileNavActionClasses}
                selected={false}
                value="more"
                disabled={false}
                onClick={handleToggleMore}
              />
            )}
          </BottomNavigation>
        </Hidden>
      )}
    </>
  )
}

export default MobileBottomNav
