import React, { useState } from "react"
import { Button, Hidden, makeStyles, Box } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import { FloatingSpeedDial, Icon, RequirePermissions } from ".."
import { useAuth } from "../../services"

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const requiresPermissions = ["timer_create"]

const DesktopCreateTimersButton = ({ onCreateClick }) => {
  const { hasFeature } = useAuth()

  const handleToggleCreateMenu = () => {
    onCreateClick && onCreateClick()
  }

  const hasTimers = hasFeature("timers")

  return (
    <RequirePermissions operator="or" requires={requiresPermissions}>
      {hasTimers && (
        <Box display="flex" flexDirection="row">
          <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-label">
            <Trans>Create</Trans>
          </Button>
        </Box>
      )}
    </RequirePermissions>
  )
}

const MobileCreateTimersButton = ({ creatorOpen, onCreateClick }) => {
  const classes = useSpeedDialActionStyles()
  const { hasFeature } = useAuth()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreateClick = (type) => {
    handleToggleSpeedDial()
    onCreateClick && onCreateClick(type)
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  const hasTimers = hasFeature("timers")

  return (
    <RequirePermissions operator="or" requires={requiresPermissions}>
      {hasTimers && !creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <RequirePermissions requires={["timers_create"]}>
            <Hidden smDown>
              <SpeedDialAction
                tooltipTitle={<Trans>Create a new timers</Trans>}
                icon={<Icon name="timers-template" />}
                tooltipOpen
                open={openSpeedDial}
                onClick={() => handleCreateClick("template")}
                classes={classes}
              />
            </Hidden>
          </RequirePermissions>
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateTimersButton, MobileCreateTimersButton }
