import React from "react"
import { Grid, Box, Chip, Paper, Typography, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useLocation } from "react-router-dom"
import { t } from "@lingui/macro"
import { Icon } from "../Icon"
import { secondsOf2hrs, secondsOf4hrs, toId, useDateUtils, useTimerUtils } from "../../utils"
import { TIMER_STATUS } from "../../data"
import { RowBox } from "../Boxes"
import { IntervalRenderer } from "../IntervalRenderer"
import { DisplayImage } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: 1,
    cursor: "pointer",
    display: "flex",
  },
  paper: {
    "&:hover": theme.cards.hoverCard,
    "&:focus": theme.cards.hoverCard,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  paperWarning: {
    border: `1px solid ${theme.palette.scheduled.main}`,
    backgroundColor: theme.palette.warning.background,
  },
  paperError: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.background,
  },
  timerGrid: {
    display: "flex",
  },
  titleBox: {
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: "64px",
    maxHeight: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  caption: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  imageFrame: {
    position: "relative",
  },
  timerChip: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "80%",
  },
  timerCount: {
    borderRadius: "100px",
    padding: 4,
  },
  timerCountText: {
    padding: "3px 6px",
    fontSize: 13,
    fontWeight: "500",
    lineHeight: "18px",
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: "white",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  paused: {
    color: "white",
    backgroundColor: theme.palette.success.main,
  },
  running: {
    color: "white",
    backgroundColor: theme.palette.info.main,
  },
  timerImage: {
    backgroundColor: "#1a1b43",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "140px",
    [theme.breakpoints.down("sm")]: {
      height: "130px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "120px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  defaultImagePadding: {
    padding: theme.spacing(2),
  },
  defaultImage: {
    borderRadius: 4,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  customImage: {
    width: "100%",
    objectFit: "cover",
    minHeight: "100%",
  },
}))

const TimersItem = ({ item }) => {
  const classes = useStyles()
  const location = useLocation()
  const { totalSecondsElapsed } = useTimerUtils()
  const { id } = item

  const pathname = item?.activeTimer ? `/timers/food-items/${id}/edit` : `/timers/food-items/${toId(item)}/new`

  const image = item?.image || item?.foodItem?.image || null

  return (
    <IntervalRenderer delay={1000}>
      {() => {
        const elapsed = item?.status === TIMER_STATUS.ACTIVE ? totalSecondsElapsed(item) : item?.totalSecondsElapsed

        const colorClass = (() => {
          if (!item?.activeTimer || item?.status !== TIMER_STATUS.ACTIVE) return null

          if (elapsed > secondsOf4hrs) return classes.paperError
          if (elapsed > secondsOf2hrs) return classes.paperWarning

          return null
        })()
        return (
          <Grid item lg={3} md={3} sm={4} xs={6} className={classes.timerGrid}>
            <Box component={NavLink} to={{ pathname, state: { background: location } }} className={classes.root}>
              <Paper elevation={0} className={[classes.paper, colorClass].join(" ")}>
                <Box className={classes.imageFrame}>
                  <TimerChip item={item} elapsed={elapsed} />
                  <Box className={classes.timerImage}>
                    {image && <DisplayImage className={classes.image} fit="inside" upload={image} />}
                  </Box>
                </Box>
                <RowBox className={classes.titleBox}>
                  <RowBox flexGrow={1}>
                    <Box display="flex" flexDirection="column" gridGap={2}>
                      <Typography variant="h4" className={classes.title}>
                        {item?.foodItem?.name || item?.name}
                      </Typography>
                      {item?.name && item?.foodItem && (
                        <Typography variant="caption" className={classes.caption}>
                          {item?.name}
                        </Typography>
                      )}
                    </Box>
                  </RowBox>
                  <TimerButton item={item} elapsed={elapsed} />
                </RowBox>
              </Paper>
            </Box>
          </Grid>
        )
      }}
    </IntervalRenderer>
  )
}

const TimerButton = ({ item, elapsed }) => {
  const classes = useStyles()
  const { formatSecondsToHMM } = useDateUtils()

  if (!item?.activeTimer) {
    return (
      <IconButton className={classes.paused} size="small">
        <Icon name="unpause" />
      </IconButton>
    )
  }

  const colorClass = (() => {
    if (item?.status === TIMER_STATUS.ACTIVE) {
      if (elapsed > secondsOf4hrs) return classes.error
      if (elapsed > secondsOf2hrs) return classes.warning
      return classes.running
    }
    return classes.paused
  })()

  return (
    <RowBox className={[classes.timerCount, colorClass].join(" ")}>
      <Icon name="timer-clock" reversed />
      <Typography className={classes.timerCountText}>{formatSecondsToHMM(elapsed)}</Typography>
    </RowBox>
  )
}

const TimerChip = ({ item, elapsed }) => {
  const classes = useStyles()

  if (item?.activeTimer) {
    if (item?.status === TIMER_STATUS.ACTIVE) {
      if (elapsed > secondsOf4hrs) {
        return <Chip label={t`Running 4hr+`} size="small" className={[classes.timerChip, classes.error].join(" ")} />
      }
      if (elapsed > secondsOf2hrs) {
        return <Chip label={t`Running 2hr+`} size="small" className={classes.timerChip} />
      }
      return <Chip label={t`Running`} size="small" className={classes.timerChip} />
    }
    if (item?.status === TIMER_STATUS.PAUSED)
      return <Chip label={t`Paused`} size="small" className={classes.timerChip} />
  }
  return null
}

export { TimersItem }
