import React from "react"
import { Box, Grid, List, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useParams, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { SubNavigationListItem, SubNavigation } from ".."

const useStyles = makeStyles((theme) => ({
  horizontalNavContainer: {
    zIndex: 200,
    background: theme.palette.background.default,
  },
  horizontalNavItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    overflowX: "auto",
    "& li": {
      display: "inline-table",
      minWidth: "100px",
      borderRadius: theme.spacing(0.5),
      "& a": {
        justifyContent: "space-around",
        borderRadius: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
}))

const listCategories = [
  {
    id: "food-items",
    name: "Food Items",
  },
]

const TimersSubNav = ({ variant }) => {
  const classes = useStyles()
  const { id } = useParams()
  const location = useLocation()

  // Lists the categories in this organisation
  const categoriesNav = listCategories && (
    <List>
      {listCategories.map(({ id: catId, name }) => (
        <SubNavigationListItem key={catId} to="/timers/food-items" text={name} badgeVariant="text" active />
      ))}
      {/* <SubNavigationListItem text="General" to="/timers" active={location.pathname === "/timers"} /> */}
    </List>
  )

  // Show on left hand side in desktop views
  if (variant === "vertical") {
    return (
      <Hidden smDown>
        <SubNavigation title={<Trans>Categories</Trans>}>{categoriesNav}</SubNavigation>
      </Hidden>
    )
  }

  // Show as horizontal scroller on mobile and kiosk
  if (variant === "horizontal") {
    return (
      <Grid container direction="row" className={classes.horizontalNavContainer}>
        <Grid item sm={12} xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row" mb={1}>
            <List className={classes.horizontalNavItem}>
              {listCategories.map(({ id: catId, name }) => (
                <SubNavigationListItem
                  variant="horizontal"
                  to={`/timers/category/${catId}`}
                  key={catId}
                  text={name}
                  value={catId}
                  active={id === catId}
                />
              ))}
              <SubNavigationListItem
                variant="horizontal"
                text={<Trans>General</Trans>}
                to="/timers"
                active={location.pathname === "/timers"}
              />
            </List>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return null
}

export { TimersSubNav }
