import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Badge, Box, IconButton, Popover, Typography, useTheme } from "@material-ui/core"
import { anchorRef, bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks"
import { Trans } from "@lingui/macro"
import { useHistory } from "react-router-dom"
import { Icon } from "../Icon"
import { RowBox } from "../Boxes"
import { IntervalRenderer } from "../IntervalRenderer"
import { secondsOf2hrs, secondsOf4hrs, toId, useDateUtils, useTimerUtils } from "../../utils"
import { TIMER_STATUS, useQueryTimers } from "../../data"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 999,
  },
  speedDial: {
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
    },
    position: "fixed",
    bottom: 80,
    left: 20,
    boxShadow:
      "0px 1px 8px 0px rgba(32, 146, 255, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px -2px rgba(32, 146, 255, 0.15)",
  },
  badge: {
    position: "fixed",
    bottom: 120,
    left: 60,
  },
  icon: {
    borderRadius: 100,
    padding: 4,
    height: 32,
    width: 32,
    backgroundColor: ({ display }) => display?.backgroundColor || theme.palette.info.main,
  },
  iconMenuWrapper: {
    position: "relative",
    display: "inline-block",
    width: 24,
    height: 24,
  },
  iconMenu: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.3s ease, transform 0.3s ease",
    opacity: 1,
    transform: "scale(1)",
  },
  hidden: {
    opacity: 0,
    transform: "scale(0.8)",
  },
  containerRow: {
    justifyContent: "space-between",
    cursor: "pointer",
  },
  containerTitle: {
    maxWidth: 230,
    flexGrow: 1,
    gridGap: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: ({ display }) => display?.color || theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  caption: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
}))

const usePopoverStyles = makeStyles((theme) => ({
  root: {
    width: 340,
    marginTop: theme.spacing(-1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
    maxHeight: ({ reminder }) => (reminder ? null : "200px"),
  },
}))

const TimerFloatingSpeedDial = () => {
  const history = useHistory()
  const { location } = useAuth()

  const popupState = usePopupState({
    variant: "popover",
    popupId: "timersButton",
  })

  const [timersUnsubscribes, setTimersUnsubscribes] = useState(null)
  const popoverClasses = usePopoverStyles()
  const classes = useStyles()

  const { data, called, subscribe } = useQueryTimers({
    variables: {
      filter: {
        location: toId(location),
      },
    },
  })

  useEffect(() => {
    if (called && !timersUnsubscribes) {
      setTimersUnsubscribes(subscribe())
      return () => {
        if (timersUnsubscribes) {
          timersUnsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [timersUnsubscribes, called, subscribe])

  const handleClick = ({ item }) => {
    history.push(`/timers/food-items/${toId(item)}/edit`)
  }

  const timers =
    data?.timers?.list?.filter((item) => [TIMER_STATUS.ACTIVE, TIMER_STATUS.PAUSED].includes(item.status)) || []

  if (timers.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <Badge badgeContent={timers.length} color="primary" className={classes.badge} overlap="circular">
        <IconButton className={classes.speedDial} {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
          <div className={classes.iconMenuWrapper}>
            <Icon name="timer-clock" className={`${classes.iconMenu} ${popupState.isOpen ? classes.hidden : ""}`} />
            <Icon name="clear" className={`${classes.iconMenu} ${!popupState.isOpen ? classes.hidden : ""}`} />
          </div>
        </IconButton>
      </Badge>

      <Popover
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={popoverClasses}
      >
        <IntervalRenderer delay={1000}>
          {() => (
            <Box p={2} display="flex" flexDirection="column" gridGap={12}>
              {timers.map((item) => (
                <TimerRow key={`timer-row-${item.id}`} timer={item} onClick={() => handleClick({ item })} />
              ))}
            </Box>
          )}
        </IntervalRenderer>
      </Popover>
    </div>
  )
}

const TimerRow = ({ timer, onClick }) => {
  const theme = useTheme()
  const { formatSecondsToHMMSS } = useDateUtils()
  const { totalSecondsElapsed } = useTimerUtils()

  let display = {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
  }

  const elapsed = totalSecondsElapsed(timer)
  if (timer?.status === TIMER_STATUS.ACTIVE) {
    if (elapsed > secondsOf2hrs) {
      display = {
        backgroundColor: theme.palette.warning.main,
      }
    }
    if (elapsed > secondsOf4hrs) {
      display = {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.main,
      }
    }
  }
  if (timer?.status === TIMER_STATUS.PAUSED) {
    display = {
      backgroundColor: theme.palette.success.main,
    }
  }

  const classes = useStyles({ display })

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <RowBox className={classes.containerRow} onClick={handleClick}>
      <RowBox className={classes.containerTitle}>
        <Box className={classes.icon}>
          <Icon name="timer-clock" reversed />
        </Box>
        <Box>
          <Typography className={classes.title}>{timer?.foodItem?.name}</Typography>
          <Typography className={classes.caption}>
            <Trans>{timer?.name}</Trans>
          </Typography>
        </Box>
      </RowBox>
      <Typography className={classes.title}>{formatSecondsToHMMSS(totalSecondsElapsed(timer))}</Typography>
    </RowBox>
  )
}

export { TimerFloatingSpeedDial }
