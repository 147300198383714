import React, { useState } from "react"
import { AppBar, Toolbar, Container, Box, useTheme, useMediaQuery } from "@material-ui/core"
import { NavLink, useLocation } from "react-router-dom"
import Config from "react-global-configuration"
import { makeStyles } from "@material-ui/styles"
import { NavLinkItem, Logo, getImageSource } from ".."
import HeaderTray from "./HeaderTray"
import { useAuth } from "../../services"
import { useQuerySettings } from "../../data"
import { PunchClock } from "../PunchClock"
import { useQueryAreas } from "../../data/areas/useQueryAreas"
import { getViewIcon } from "../../areas/account/Views/utils"
import { mapToIds, toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: ({ hasPunchClock }) =>
      hasPunchClock
        ? theme.dimensions.header.height + theme.dimensions.punchClock.height
        : theme.dimensions.header.height,
    paddingRight: 0,
    overflow: ({ hasPunchClock }) => (hasPunchClock ? "hidden" : "visible"),
    transition: "height 0.6s ease",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderTop: "none",
  },
  toolbar: {
    height: theme.dimensions.header.height,
    minHeight: theme.dimensions.header.height,
    paddingRight: 0,
  },
  logoContainer: {
    display: "flex",
  },
  desktopLogo: {
    width: 32,
    height: 32,
  },
  mobileLogo: {
    display: "flex",
    flexDirection: "column",
  },
  customLogo: {
    maxWidth: 80,
    maxHeight: 40,
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 12,
    gap: ({ short }) => (short ? theme.spacing(0.5) : theme.spacing(1)),
  },
  navLink: {
    color: `${theme.palette.text.primary} !important`,
    fontFamily: theme.typography.fontFamily,
    marginRight: 0,
    textAlign: "center",
    fontSize: 13,
    fontWeight: "600",
    height: 40,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    "&:hover": {
      background: theme.palette.action.hover,
      transition: "all 0.2s ease",
    },
    "&:focus-visible": {
      background: theme.palette.action.focus,
      outline: "none",
    },
  },
  navLinkWithMenu: {
    padding: ({ short }) => (short ? "0 8px 0 8px" : "0 8px 0 8px"),
  },
  navLinkActive: {
    position: "relative",
    display: "flex",
    "&:focus-visible": {
      background: theme.palette.grey[100],
      outline: "none",
      "&::after": {
        outline: "none",
      },
    },
    "&::after": {
      content: '""',
      height: "3px",
      width: "100%",
      outline: "none",
      background: theme.palette.gradientOrange,
      position: "absolute",
      top: 47,
      left: 0,
      borderRadius: "3px 3px 0px 0px",
    },
  },
}))

const Header = () => {
  const theme = useTheme()
  const short = useMediaQuery(theme.breakpoints.down("sm"))
  const location = useLocation()
  const [hasPunchClock, setHasPunchClock] = useState(false)
  const {
    authed,
    permissionGroups: { readJobs, readKnowledge, readLabels, readUsers, readTemplates, readReports, adminTraining },
    featureDependencies,
    location: userLocation,
    principal: { groups: usersGroups },
    hasFeature,
    hasPermission,
    settings: { organisation: organisationSettings },
  } = useAuth()
  const { lang } = useQuerySettings()
  const { data: areaData } = useQueryAreas({
    variables: {
      filter: {
        locations: [toId(userLocation)],
        groups: mapToIds(usersGroups),
      },
    },
  })
  const { clientKiosk: kiosk } = Config.get()
  const classes = useStyles({ short, hasPunchClock })
  const { imageViewerBaseUri, imageViewerBucketName } = Config.get("uploader")

  if (!authed) return null

  const getActive = (areas) => {
    const pathStartsWith = (area) => location.pathname.startsWith(area)
    const active = Array.isArray(areas) ? areas.some((area) => pathStartsWith(area)) : pathStartsWith(areas)
    return active
  }

  const getNavLinkClassName = (areas) => {
    const active = getActive(areas)
    return `${classes.navLink} ${active ? classes.navLinkActive : ""}`
  }

  const langArea = short ? lang.areaShort : lang.area

  const hasTimesheets = hasFeature("timesheets")
  const hasLabels = hasFeature("labels")
  const isManager = hasPermission([...readTemplates, ...readUsers, ...readReports])

  const handleKioskLoggingOut = () => {
    setHasPunchClock(false)
  }

  const handlePunchClockLoaded = (show) => {
    setHasPunchClock(show)
  }

  const getAreaUrls = () => areaData?.areas?.list?.map((area) => `/area/${area.url}`) ?? []

  const getAreaNavItems = () => {
    if (areaData && Object.entries(areaData).length > 0) {
      return areaData?.areas?.list?.map((area) => {
        const areaUrl = `/area/${area.url}`

        return {
          text: area.name,
          icon: getViewIcon(area?.icon),
          to: areaUrl,
          requires: "area_read",
          selected: getActive(`${areaUrl}/`), // ! Hack solution. We need to improve getActive function.
          beta: true,
        }
      })
    }

    return []
  }

  return (
    <>
      <AppBar position="sticky" className={classes.header}>
        <Container>
          <Toolbar className={classes.toolbar} disableGutters>
            {authed && (
              <>
                {hasFeature("whitelabel") && organisationSettings?.appLogo ? (
                  <Box>
                    <NavLink to="/dashboard" className={classes.logoContainer}>
                      <img
                        alt={organisationSettings.name}
                        src={getImageSource(
                          imageViewerBaseUri,
                          imageViewerBucketName,
                          { key: organisationSettings.appLogo },
                          "contain",
                          organisationSettings?.appLogoLandscape ? 240 : 120,
                          120,
                          true,
                          { resize: { background: { r: 0, g: 0, b: 0, alpha: 0 } } },
                        )}
                        className={classes.customLogo}
                      />
                    </NavLink>
                  </Box>
                ) : (
                  <Box>
                    <NavLink to="/dashboard" className={classes.logoContainer}>
                      <Logo className={classes.desktopLogo} link={false} />
                    </NavLink>
                  </Box>
                )}
                <Box display={{ xs: "none", sm: "block" }}>
                  <nav className={classes.nav}>
                    <NavLinkItem
                      to="/dashboard"
                      text={langArea.dashboard}
                      className={getNavLinkClassName("/dashboard")}
                      disabled={!userLocation}
                    />
                    <NavLinkItem
                      requires={readJobs}
                      to="/jobs"
                      text={langArea.jobs}
                      className={getNavLinkClassName("/jobs")}
                      disabled={!userLocation}
                    />
                    <NavLinkItem
                      requires={readKnowledge}
                      operator="and"
                      to="/knowledge"
                      text={langArea.knowledge}
                      className={getNavLinkClassName("/knowledge")}
                    />
                    {hasLabels && (
                      <NavLinkItem
                        requires={readLabels}
                        operator="and"
                        to="/labels"
                        text={langArea.labels}
                        className={getNavLinkClassName("/labels")}
                      />
                    )}
                    <NavLinkItem
                      requires="post_read"
                      to="/hub"
                      text={langArea.hub}
                      className={getNavLinkClassName("/hub")}
                      disabled={!userLocation}
                    />

                    <NavLinkItem
                      text={isManager ? langArea.manager : langArea.more}
                      className={`${getNavLinkClassName([
                        "/people",
                        "/training",
                        "/templates",
                        "/audits",
                        "/assets",
                        "/suppliers",
                        "/sensors",
                        "/reports",
                        "/prep",
                        "/food-items",
                        "/timers",
                        ...getAreaUrls(),
                      ])} ${classes.navLinkWithMenu}`}
                      rightIcon={{ name: "submenu", fontSize: "small" }}
                      subMenu={[
                        {
                          text: langArea.templates,
                          icon: "templates-dark",
                          to: "/templates",
                          requires: readTemplates,
                          selected: getActive("/templates"),
                        },
                        ...getAreaNavItems(),
                        {
                          text: langArea.people,
                          icon: "people-dark",
                          to: "/people",
                          requires: readUsers,
                          selected: getActive("/people"),
                        },
                        {
                          text: langArea.training,
                          icon: "training-accreditation-dark",
                          to: "/training",
                          requires: adminTraining,
                          feature: "training",
                          selected: getActive("/training"),
                        },
                        {
                          text: langArea.assets,
                          icon: "assets-dark",
                          to: "/assets",
                          requires: "asset_read",
                          feature: "assets",
                          selected: getActive("/assets"),
                          beta: true,
                        },
                        {
                          text: langArea.suppliers,
                          icon: "suppliers-dark",
                          to: "/suppliers",
                          requires: "supplier_read",
                          feature: "suppliers",
                          selected: getActive("/suppliers"),
                        },
                        {
                          text: langArea.foodItems,
                          icon: "food-items-dark",
                          to: "/food-items",
                          requires: "food_item_read",
                          feature: "food",
                          selected: getActive("/food-items"),
                          beta: true,
                        },
                        {
                          text: langArea.prep,
                          icon: "prep-dark",
                          to: "/prep",
                          requires: "prep_read",
                          feature: featureDependencies.prep,
                          selected: getActive("/prep"),
                        },
                        {
                          text: langArea.timers,
                          icon: "timers-dark",
                          to: "/timers",
                          requires: "timer_read",
                          feature: featureDependencies.timers,
                          selected: getActive("/timers"),
                          beta: true,
                        },
                        {
                          text: langArea.sensors,
                          icon: "sensors-dark",
                          to: "/sensors",
                          requires: "sensor_read",
                          feature: "sensors",
                          selected: getActive("/sensors"),
                        },
                        {
                          text: langArea.reports,
                          icon: "reports-dark",
                          to: "/reports",
                          requires: readReports,
                          selected: getActive("/reports"),
                          beta: true,
                        },
                      ]}
                      cy="-managers-hub"
                    />
                  </nav>
                </Box>

                <HeaderTray onKioskLoggingOut={handleKioskLoggingOut} />
              </>
            )}
          </Toolbar>
        </Container>
        {kiosk && hasTimesheets && <PunchClock onLoaded={handlePunchClockLoaded} />}
      </AppBar>
    </>
  )
}

export default Header
